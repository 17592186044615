import AppFormGroupsSection from "@/apps/list/app/common/settings/AppFormGroupsSection"
import AppFormMembersSection from "@/apps/list/app/common/settings/AppFormMembersSection"
import AppSubmitFormSection from "@/apps/list/form-sections/AppSubmitFormSection"
import LegacyAppIconAndTitleFormSection from "@/apps/list/form-sections/LegacyAppIconAndTitleFormSection"
import {
  LegacyCreateSetupAppModalFormStore,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import { useAppLevel } from "@/apps/util/appLevelContext"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Form from "@components/form/Form"
import { DiscoSection } from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import AppVisibilityForm from "./AppVisibilityForm"

interface AppSettingsFormProps extends TestIDProps {
  formSectionBody?: React.ReactNode
  form: LegacyCreateSetupAppModalFormStore | LegacyEditSetupAppModalFormStore
  onSubmit: () => void
  onClose: () => void
  onNext?: () => void
  mode: "edit" | "add"
  hideIconAndTitle?: boolean
  hideSectionSelector?: boolean
  groupsConfig?: {
    hide?: boolean
  }
  membersConfig?: {
    hide?: boolean
  }
  visibilityConfig?: {
    hide?: boolean
    disable?: boolean
    alert?: React.ReactNode
  }
  footerLhs?: React.ReactNode
}

function AppSettingsForm({
  testid,
  mode,
  form,
  formSectionBody,
  onSubmit,
  onClose,
  onNext,
  hideIconAndTitle,
  hideSectionSelector,
  groupsConfig = { hide: false },
  membersConfig = { hide: false },
  visibilityConfig = { hide: false, disable: false },
  footerLhs,
}: AppSettingsFormProps) {
  const activeOrganization = useActiveOrganization()!
  const { product } = useAppLevel()
  const { productLevelAppPermissions } = useFeatureFlags()

  return (
    <Form
      testid={"AppSetupModalForm"}
      errorInfo={null}
      knownErrorKeys={form.errors.map((e) => e.field)}
      onSubmit={onSubmit}
      buttons={
        <>
          {footerLhs}
          <AppSubmitFormSection
            form={form}
            mode={mode}
            onClose={onClose}
            onNext={onNext}
          />
        </>
      }
    >
      <LegacyAppIconAndTitleFormSection
        form={form}
        hideIconAndTitle={hideIconAndTitle}
        hideSectionSelector={hideSectionSelector}
      />

      {(product ? productLevelAppPermissions : true) && !visibilityConfig.hide && (
        <>
          <AppVisibilityForm
            testid={testid}
            form={form}
            alert={visibilityConfig.alert}
            disabled={visibilityConfig.disable}
          />

          {/** App visibility settings */}
          {form.state.visibility === "membership" && (
            <DiscoSection
              groovyDepths={"insideCard"}
              padding={1.5}
              marginTop={-1}
              marginLeft={0.25}
              marginRight={0.25}
              marginBottom={2.5}
            >
              {!groupsConfig.hide && (
                <AppFormGroupsSection
                  testid={`${testid}.access`}
                  memberGroupIds={toJS(form.state.visibilityGroups!)}
                  productId={product?.id}
                  organizationId={activeOrganization.id}
                  onChange={(groupIds) => form.state.visibilityGroups!.replace(groupIds)}
                  errorMessages={form.errorsByField.visibilityGroups}
                  hideCommunityGroups={product ? true : undefined}
                  disabled={visibilityConfig.disable}
                />
              )}

              {!membersConfig.hide && (
                <AppFormMembersSection
                  testid={`${testid}.access`}
                  membershipIds={toJS(form.state.visibilityMembers!)}
                  productId={product?.id}
                  onChange={(memberships) =>
                    form.state.visibilityMembers!.replace(memberships.map((m) => m.id))
                  }
                  errorMessages={form.errorsByField.visibilityMembers}
                />
              )}
            </DiscoSection>
          )}

          {formSectionBody}
        </>
      )}
    </Form>
  )
}

export default observer(AppSettingsForm)
